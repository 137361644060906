import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Typography,
  CardContent,
  Menu,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Card,
  MenuItem,
  CardHeader,
  Switch,
  Modal,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { getLastId } from "../../helpers";

const CreateModal = ({
  classes,
  showCreateModal,
  keysForEdit,
  changeState,
  createKey
}) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={showCreateModal}
    onClose={() => changeState({ showCreateModal: false })}
  >
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`
      }}
      className={classes.paper}
    >
      <h3 id="modal-title" className={classes.modalTitle}>
        Edit a Key
      </h3>
      <TextField
        id="standard-name"
        label="Key name"
        className={classes.modalTextField}
        value={keysForEdit !== null ? keysForEdit.title : ""}
        onChange={event => {
          const value = event.target.value;
          changeState(state => ({
            keysForEdit: {
              ...state.keysForEdit,
              title: value
            }
          }));
        }}
        margin="normal"
      />
      <div className={classes.modalControl}>
        <p className={classes.menu_toggle_text}>Required</p>
        <Switch
          checked={keysForEdit.isRequired}
          onChange={() =>
            changeState(state => ({
              keysForEdit: {
                ...state.keysForEdit,
                isRequired: !state.keysForEdit.isRequired
              }
            }))
          }
          color="primary"
        />
      </div>
      <TextField
        id="key-description"
        label="Description"
        multiline
        className={classes.keysDescription}
        rows="10"
        value={keysForEdit.subtitle}
        onChange={event => {
          const value = event.target.value;
          changeState(state => ({
            keysForEdit: {
              ...state.keysForEdit,
              subtitle: value
            }
          }));
        }}
        margin="normal"
        placeholder="Please, add a description for this key"
        variant="outlined"
      />
      <div className={classes.modalControl}>
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
          onClick={createKey}
        >
          Create
        </Button>
        <Button
          className={classes.modalButton}
          onClick={() => changeState({ showCreateModal: false })}
        >
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

const EditModal = ({ classes, showEditModal, keysForEdit, changeState }) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={showEditModal}
    onClose={() => changeState({ showEditModal: false, keysForEdit: null })}
  >
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`
      }}
      className={classes.paper}
    >
      <h3 id="modal-title" className={classes.modalTitle}>
        Edit a Key
      </h3>
      <TextField
        id="standard-name"
        label="Key name"
        className={classes.modalTextField}
        value={keysForEdit !== null ? keysForEdit.title : ""}
        onChange={event => {
          const value = event.target.value;
          changeState(state => ({
            keysForEdit: {
              ...state.keysForEdit,
              title: value
            }
          }));
        }}
        margin="normal"
      />
      <div className={classes.modalControl}>
        <p className={classes.menu_toggle_text}>Required</p>
        <Switch
          checked={keysForEdit.isRequired}
          onChange={() =>
            changeState(state => ({
              keysForEdit: {
                ...state.keysForEdit,
                isRequired: !state.keysForEdit.isRequired
              }
            }))
          }
          color="primary"
        />
      </div>
      <TextField
        id="key-description"
        label="Description"
        multiline
        className={classes.keysDescription}
        rows="10"
        value={keysForEdit.subtitle}
        onChange={event => {
          const value = event.target.value;
          changeState(state => ({
            keysForEdit: {
              ...state.keysForEdit,
              subtitle: value
            }
          }));
        }}
        margin="normal"
        placeholder="Please, add a description for this key"
        variant="outlined"
      />
      <div className={classes.modalControl}>
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
          onClick={() =>
            changeState(state => ({
              showEditModal: false,
              keysForEdit: null,
              keys: state.keys.map(
                keys =>
                  keys.id === keysForEdit.id
                    ? {
                        ...keys,
                        title: keysForEdit.title,
                        isRequired: keysForEdit.isRequired,
                        subtitle: keysForEdit.subtitle
                      }
                    : keys
              )
            }))
          }
        >
          Save
        </Button>
        <Button
          className={classes.modalButton}
          onClick={() =>
            changeState({
              showEditModal: false,
              keysForEdit: null
            })
          }
        >
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

const DashboardCard = ({
  classes,
  menuEl,
  handleMenuClose,
  handleMenuClick,
  handleEditModal,
  keys: { id, title, subtitle, isRequired },
  deleteCard
}) => (
  <Card className={classes.card}>
    <CardHeader
      action={
        <React.Fragment>
          <IconButton id={`keys_${id}`} onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`keys-menu_${id}`}
            anchorEl={menuEl}
            open={Boolean(menuEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEditModal}>Edit</MenuItem>
            <MenuItem onClick={deleteCard}>Delete</MenuItem>
          </Menu>
        </React.Fragment>
      }
      title={title}
      subheader={subtitle}
    />
    <CardContent>
      {isRequired && (
        <div className={classes.requireRow}>
          <Typography component="p">Required</Typography>
          <i className="material-icons">check_circle</i>
        </div>
      )}
    </CardContent>
  </Card>
);

class Keys extends React.Component {
  state = {
    search: "",
    menuEl: null,
    keysForEdit: null,
    showEditModal: false,
    showCreateModal: false,
    keys: [
      {
        title: "Key 1",
        subtitle: "Version XYZ",
        isRequired: true,
        id: 1
      },
      {
        title: "Key 2",
        subtitle: "Version XYZ",
        isRequired: false,
        id: 2
      },
      {
        title: "Key 3",
        subtitle: "Version XYZ",
        isRequired: true,
        id: 3
      }
    ]
  };

  handleMenuClick = event => {
    const target = event.currentTarget;
    const keysId = parseInt(target.id.toString().replace(/keys_/, ""), 10);
    this.setState(state => ({
      menuEl: target,
      keysForEdit: state.keys.find(keys => keys.id === keysId)
    }));
  };

  handleMenuClose = () => {
    this.setState({ menuEl: null });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  deleteCard = () => {
    const { keysForEdit } = this.state;
    this.setState(state => ({
      menuEl: null,
      keys: state.keys.filter(keys => keys.id !== keysForEdit.id)
    }));
  };

  createKey = () => {
    this.setState(state => ({
      showCreateModal: false,
      keys: [
        ...state.keys,
        { ...state.keysForEdit, id: getLastId(state.keys) + 1 }
      ]
    }));
  };

  handleEditModal = () => {
    this.setState({
      menuEl: null,
      showEditModal: true
    });
  };

  render() {
    const { classes } = this.props;
    const {
      keys,
      menuEl,
      search,
      showEditModal,
      keysForEdit,
      showCreateModal
    } = this.state;
    return (
      <div>
        <div className={classes.header}>
          <h2>Key</h2>
          <Input
            id="adornment-password"
            value={search}
            onChange={this.handleChange("search")}
            placeholder="Search…"
            className={classes.header_input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            className={classes.header_button}
            onClick={() =>
              this.setState({
                showCreateModal: true,
                keysForEdit: {
                  isRequired: false
                }
              })
            }
          >
            Create
          </Button>
        </div>
        <GridContainer>
          {keys.map(keys => (
            <GridItem xs={12} sm={6} md={4} key={`${keys.title}_${keys.id}`}>
              <DashboardCard
                classes={classes}
                menuEl={menuEl}
                handleMenuClick={this.handleMenuClick}
                handleMenuClose={this.handleMenuClose}
                handleEditModal={this.handleEditModal}
                keys={keys}
                deleteCard={this.deleteCard}
              />
            </GridItem>
          ))}
        </GridContainer>
        <EditModal
          changeState={data => this.setState(data)}
          classes={classes}
          showEditModal={showEditModal}
          keysForEdit={keysForEdit || {}}
        />
        <CreateModal
          changeState={data => this.setState(data)}
          classes={classes}
          showCreateModal={showCreateModal}
          createKey={this.createKey}
          keysForEdit={keysForEdit || {}}
        />
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(Keys);
