import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CardContent,
  Menu,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Card,
  MenuItem,
  CardHeader,
  Switch,
  Modal,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const ranges = [
  {
    value: ",",
    label: ","
  },
  {
    value: ".",
    label: "."
  },
  {
    value: "space",
    label: "space"
  }
];

const UploadModal = ({ classes, showUploadModal, changeState }) => (
  <Modal
    aria-labelledby="simple-upload-modal"
    aria-describedby="simple-upload-modal"
    open={showUploadModal}
    onClose={() => changeState({ showUploadModal: false })}
  >
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`
      }}
      className={classes.paper}
    >
      <h3 id="modal-title" className={classes.modalTitle}>
        Upload a new Footprint
      </h3>
      <TextField
        id="new-mapping-name"
        label="Footprint name"
        className={classes.modalTextField}
        margin="normal"
        placeholder="Enter the name of this footprint"
      />
      <TextField
        id="new-mapping"
        label="Select Mapping"
        className={classes.modalTextField}
        placeholder="Type the name or choose from the list"
        margin="normal"
      />
      <div className={classes.uploadControl}>
        <p className={classes.menu_toggle_text}>
          Header <Switch value="checkedB" />
        </p>
        <p className={classes.menu_toggle_text}>
          Text Fields Quoted? <Switch value="checkedB" />
        </p>
      </div>
      <div className={classes.uploadControl}>
        <TextField
          select
          variant="filled"
          label="Standard Delimiter"
          placeholder="Choose a Delimiter"
          className={classes.uploadSelect}
          margin="normal"
        >
          {ranges.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <p>-OR-</p>
        <TextField
          className={classes.uploadCustomDelimiter}
          id="custom-delimiter"
          label="Custom Delimiter"
          margin="normal"
          variant="outlined"
          defaultValue=" "
        />
      </div>
      <div className={classes.modalControl}>
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
        >
          Upload
        </Button>
        <Button
          className={classes.modalButton}
          onClick={() => changeState({ showUploadModal: false })}
        >
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

const EditModal = ({
  classes,
  showEditModal,
  footprintForEdit,
  changeState
}) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={showEditModal}
    onClose={() => changeState({ showEditModal: false })}
  >
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`
      }}
      className={classes.paper}
    >
      <h3 id="modal-title" className={classes.modalTitle}>
        Edit a Footprint
      </h3>
      <TextField
        id="standard-name"
        label="Footprint name"
        className={classes.modalTextField}
        value={footprintForEdit !== null ? footprintForEdit.title : ""}
        onChange={event => {
          const value = event.target.value;
          changeState(state => ({
            footprintForEdit: {
              ...state.footprintForEdit,
              title: value
            }
          }));
        }}
        margin="normal"
      />
      <div className={classes.modalControl}>
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
          onClick={() =>
            changeState(state => ({
              showEditModal: false,
              footprintForEdit: null,
              footprints: state.footprints.map(
                footprint =>
                  footprint.id === footprintForEdit.id
                    ? {
                        ...footprint,
                        title: footprintForEdit.title
                      }
                    : footprint
              )
            }))
          }
        >
          Save
        </Button>
        <Button
          className={classes.modalButton}
          onClick={() => changeState({ showEditModal: false })}
        >
          Cancel
        </Button>
      </div>
    </div>
  </Modal>
);

const DashboardCard = ({
  classes,
  menuEl,
  handleMenuClose,
  handleMenuClick,
  handleEditModal,
  footprint: { id, title, subtitle }
}) => (
  <Card className={`${classes.card}, ${id}`}>
    <CardHeader
      action={
        <div id={`footprint_${id}`}>
          <IconButton id={`footprint_${id}`} onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`footprint-menu_${id}`}
            anchorEl={menuEl}
            open={Boolean(menuEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEditModal}>Edit</MenuItem>
            <MenuItem onClick={handleMenuClose}>Upload Alt</MenuItem>
            <MenuItem onClick={handleMenuClose}>Version</MenuItem>
            <MenuItem>
              <p className={classes.menu_toggle_text}>Publish</p>
              <Switch value="checkedB" color="primary" />
            </MenuItem>
          </Menu>
        </div>
      }
      id={`footprint_${id}`}
      title={title}
      subheader={subtitle}
    />
    <CardContent />
  </Card>
);

class Footprints extends React.Component {
  state = {
    search: "",
    menuEl: null,
    footprintForEdit: null,
    showEditModal: false,
    showUploadModal: false,
    footprints: [
      {
        title: "Footprint 1",
        subtitle: "Version XYZ",
        id: 1
      },
      {
        title: "Footprint 2",
        subtitle: "Version XYZ",
        id: 2
      },
      {
        title: "Footprint 3",
        subtitle: "Version XYZ",
        id: 3
      }
    ]
  };

  handleMenuClick = event => {
    const target = event.currentTarget;
    const footprintId = parseInt(
      target.id.toString().replace(/footprint_/, ""),
      10
    );
    this.setState(state => ({
      menuEl: target,
      footprintForEdit: state.footprints.find(
        footprint => footprint.id === footprintId
      )
    }));
  };

  handleMenuClose = () => {
    this.setState({ menuEl: null, footprintForEdit: null });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleEditModal = () => {
    this.setState({
      menuEl: null,
      showEditModal: true
    });
  };

  changeState = data => this.setState(data);

  render() {
    const { classes } = this.props;
    const {
      footprints,
      menuEl,
      search,
      showEditModal,
      footprintForEdit,
      showUploadModal
    } = this.state;
    return (
      <div>
        <div className={classes.header}>
          <h2>Footprints</h2>
          <Input
            id="adornment-password"
            value={search}
            onChange={this.handleChange("search")}
            placeholder="Search…"
            className={classes.header_input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            className={classes.header_button}
            onClick={() => this.setState({ showUploadModal: true })}
          >
            Upload
          </Button>
        </div>
        <GridContainer>
          {footprints.map(footprint => (
            <GridItem
              xs={12}
              sm={6}
              md={4}
              key={`${footprint.title}_${footprint.id}`}
            >
              <DashboardCard
                classes={classes}
                menuEl={menuEl}
                handleMenuClick={this.handleMenuClick}
                handleMenuClose={this.handleMenuClose}
                handleEditModal={this.handleEditModal}
                footprint={footprint}
                key={`${footprint.title}_${footprint.id}`}
              />
            </GridItem>
          ))}
        </GridContainer>
        <EditModal
          classes={classes}
          footprintForEdit={footprintForEdit}
          changeState={this.changeState}
          showEditModal={showEditModal}
        />
        <UploadModal
          showUploadModal={showUploadModal}
          classes={classes}
          changeState={this.changeState}
        />
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(Footprints);
