import { successColor } from "assets/jss/material-dashboard-react.jsx";

const dashboardStyle = {
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "40px",
    "& h2": {
      margin: 0,
      flex: "1 1 0"
    }
  },
  subheader: {
    background: "rgb(126,166,224)",
    color: "black",
    fontWeight: "400",
    textAlign: "center"
  },
  column: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  menu: {
    width: "100%"
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  buttonColumn: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingTop: "88px"
  },
  menu_toggle_text: {
    marginRight: "10px"
  },
  header_button: {
    marginLeft: "40px",
    width: "150px"
  },
  header_input: {
    flex: "2 1 0"
  },
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: "#999999",
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  card: {
    margin: "15px 0"
  },
  cardCategory: {
    color: "#999999",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  requireRow: {
    display: "flex",
    alignItems: "center",
    "& i": {
      fontSize: "16px",
      marginLeft: "6px",
      color: "#15d715"
    }
  },
  keysDescription: {
    width: "100%"
  },
  uploadControl: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  uploadSelect: {
    minWidth: "170px"
  },
  uploadCustomDelimiter: {
    width: "145px"
  },
  modalTitle: {
    fontWeight: "400",
    margin: 0
  },
  modalControl: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  modalButton: {
    margin: "5px"
  },
  modalTextField: {
    width: "100%"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  paper: {
    width: "400px",
    padding: "16px 24px",
    position: "absolute",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    backgroundColor: "#fff"
  }
};

export default dashboardStyle;
