import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, Input, MenuItem, TextField } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { getLastId } from "../../helpers";

class newMapping extends React.Component {
  state = {
    keys: [
      {
        name: null,
        id: 1,
        columnNumber: null
      },
      {
        name: null,
        id: 2,
        columnNumber: null
      },
      {
        name: null,
        id: 3,
        columnNumber: null
      },
      {
        name: null,
        id: 4,
        columnNumber: null
      },
      {
        name: null,
        id: 5,
        columnNumber: null
      }
    ]
  };

  deleteKey = id =>
    this.setState(state => ({
      keys: state.keys.filter(key => key.id !== id)
    }));

  addKey = () =>
    this.setState(state => ({
      keys: [
        ...state.keys,
        {
          name: null,
          id: getLastId(state.keys) + 1,
          columnNumber: null
        }
      ]
    }));

  render() {
    const { classes } = this.props;
    const { keys } = this.state;
    return (
      <div>
        <div className={classes.header}>
          <h2>Mappings</h2>
        </div>
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <h4 className={classes.subheader}>Keys</h4>
            <div className={classes.column}>
              {keys.map(key => (
                <Input
                  value={key.name}
                  key={`${key.name}-${key.id}`}
                  placeholder="Key Required *"
                />
              ))}
            </div>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <h4 className={classes.subheader}>Column Number</h4>
            <div className={classes.column}>
              {keys.map(key => (
                <Input
                  value={key.columnNumber}
                  key={key.id}
                  placeholder="Input Box (numeric value only)"
                />
              ))}
            </div>
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <div className={classes.buttonColumn}>
              {keys.map(key => (
                <i
                  onClick={() => this.deleteKey(key.id)}
                  className="material-icons"
                  style={{
                    fontSize: "26px",
                    margin: "3px 0",
                    cursor: "pointer"
                  }}
                  key={`${key.id}-${key.name}`}
                >
                  clear
                </i>
              ))}
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <TextField
              id="standard-select-currency"
              select
              fullWidth={true}
              label="Add another key name"
              onChange={this.addKey}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin="normal"
            >
              <MenuItem value={"null"}>Key name</MenuItem>
              <MenuItem value={"null"}>Key name</MenuItem>
              <MenuItem value={"null"}>Key name</MenuItem>
              <MenuItem value={"null"}>Key name</MenuItem>
              <MenuItem value={"null"}>Key name</MenuItem>
              <MenuItem value={"null"}>Key name</MenuItem>
            </TextField>
          </GridItem>
          <GridItem xs={5} sm={5} md={5} style={{ marginTop: "20px" }}>
            <div className={classes.buttonRow}>
              <Button variant="contained" style={{ margin: "0 15px" }}>
                Save
              </Button>
              <Button variant="contained">Publish</Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(newMapping);
