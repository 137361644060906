import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import MappingsPage from "views/Mappings/Mappings";
import NewMappingPage from "views/newMapping/newMapping";
import FootprintsPage from "views/Footprints/Footprints";
import KeysPage from "views/Keys/Keys";

const dashboardRoutes = [
  {
    path: "/new/mapping",
    hidden: true,
    sidebarName: "Data Mapping",
    navbarName: "Mapping",
    icon: Dashboard,
    component: NewMappingPage
  },
  {
    path: "/mappings",
    sidebarName: "Data Mapping",
    navbarName: "Mapping",
    icon: Dashboard,
    component: MappingsPage
  },
  {
    path: "/footprints",
    sidebarName: "Footprints",
    navbarName: "Footprint",
    icon: Dashboard,
    component: FootprintsPage
  },
  {
    path: "/keys",
    sidebarName: "Key manager",
    navbarName: "Key Manager",
    icon: Person,
    component: KeysPage
  },
  { redirect: true, path: "/", to: "/mappings", navbarName: "Redirect" }
];

export default dashboardRoutes;
